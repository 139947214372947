import React from 'react';


import { PlasmicComponent } from '@plasmicapp/loader-react';
import { HouseCard } from '../stories/HouseCard';
var housedata = [
  {"name": "The Sandhill", "location": "Kyabram, VIC", "basicfeatures":{"msq": 242, "beds": 6, "baths": 2}, "tags": ["Family Home", "Off-Grid", "Creator"], "id":"12345678910", "mainphoto": "https://images.squarespace-cdn.com/content/v1/5eccadbaa9658a4c95a055f3/1590549019887-7L2PJUZQ7R5A0HXDG6VJ/IMG_0673.jpg?format=1000w", ownerObject:{}},
  {"name": "The Van", "location": "Kyabram, VIC", "basicfeatures":{"msq": 242, "beds": 6, "baths": 2}, "tags": ["Family Home", "Off-Grid", "Creator"], "id":"12345678910",  "mainphoto": "https://images.squarespace-cdn.com/content/v1/5eccadbaa9658a4c95a055f3/1590549019887-7L2PJUZQ7R5A0HXDG6VJ/IMG_0673.jpg?format=1000w", ownerObject:{}},
  {"name": "The Van", "location": "Kyabram, VIC", "basicfeatures":{"msq": 242, "beds": 6, "baths": 2}, "tags": ["Family Home", "Off-Grid", "Creator"], "id":"12345678910",  "mainphoto": "https://images.squarespace-cdn.com/content/v1/5eccadbaa9658a4c95a055f3/1590549019887-7L2PJUZQ7R5A0HXDG6VJ/IMG_0673.jpg?format=1000w", ownerObject:{}}
]

export function Home() {
    return (
      <>
        
        <PlasmicComponent component="Home" componentProps={{
          fav1: GetFavs(),
          fav2: GetFavs()
        }

        }/>
      </>
    )
  }


 function GetFavs() {

    const hs = housedata.map(hit => <div id={hit.id}><HouseCard house={hit}/></div>);
    return hs;
 }
