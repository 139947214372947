//import {OnLogin, OnLogout,OnCreateAccount} from '../shared/global.js'
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router";
import {
  Dimmer,
  Segment,
  Form,
  Input,
  Accordion,
  Icon,
  Step,
  Button,
  Loader,
  Image
} from "semantic-ui-react";
import { useState, useEffect } from "react";
import {
  PlasmicRootProvider,
  PlasmicComponent,
} from "@plasmicapp/loader-react";
import { PLASMIC } from "../plasmic-init";
import Uppy from "@uppy/core";
import ImageKitUppyPlugin from "imagekit-uppy-plugin";
import { Dashboard } from "@uppy/react";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
const metaFields = [
  {
    id: "name",
    name: "File name",
    placeholder: "Enter the file name",
  },
  {
    id: "folder",
    name: "Folder path",
    placeholder: "The destination path e.g. /website-assets",
  },
  {
    id: "useUniqueFileName",
    name: "Use unique file name",
    render: function ({ value, onChange }, h) {
      return h("input", {
        type: "checkbox",
        onChange: (ev) => onChange(ev.target.checked ? "true" : "false"),
        checked: value === "" || value === "true" || value === true,
        style: {
          verticalAlign: "middle",
        },
      });
    },
  },
  {
    id: "isPrivateFile",
    name: "Private File",
    render: function ({ value, onChange }, h) {
      return h("input", {
        type: "checkbox",
        onChange: (ev) => onChange(ev.target.checked ? "true" : "false"),
        checked: value === "true" || value === true,
        style: {
          verticalAlign: "middle",
        },
      });
    },
  },
  {
    id: "tags",
    name: "Tags",
    placeholder: "Comma seperated tags e.g. t-shirt,summer",
  },
  {
    id: "customCoordinates",
    name: "Custom coodinates",
    placeholder: "Comma seperated values in format x,y,width,height",
  },
];




export function Builder() {
  const {
    loginWithRedirect,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
  } = useAuth0();
  const { id } = useParams();
  if (!isAuthenticated && !isLoading) {
    loginWithRedirect({ returnTo: window.location.origin });
  }

  //STATE HANDLERS
  const [token, settoken] = useState(null);
  const [house, sethouse] = useState(null);
  const [refreshing, setrefreshing] = useState(true);
  const [accindex, setaccindex] = useState(0);
  const [currentpage, setcurrentpage] = useState(1);
  const [step2Data, setstep2Data] = useState({});
  const [step3Data, setstep3Data] = useState({});
  const uppy = React.useMemo(() => {
    return new Uppy({ debug: true, autoProceed: true }).use(
      ImageKitUppyPlugin,
      {
        id: "ImageKit",
        authenticationEndpoint:
          process.env.REACT_APP_API_ENDPOINT + "/builder/auth",
        publicKey: "public_6dr1ANgoGxpWXbMFqqnJfkxCyJ0=",
        metaFields: [
          "useUniqueFileName",
          "tags",
          "folder",
          "isPrivateFile",
          "customCoordinates",
          "responseFields",
        ],
      }
    );
  }, []);

  uppy.on("success", (fileCount) => {
    console.log(`${fileCount} files uploaded`);
  });

  useEffect(() => {
    console.log("Getting Data");
    getData();

    async function getData() {
      console.log("got data");

      if (isLoading === true && isAuthenticated === false) {
      } else {
        // CODE GOES IN HERE

        console.log("loading is true");
        setrefreshing(true);
        if (isAuthenticated) {
          const token = await getAccessTokenSilently({
            audience: "Hosted on AWS",
          });
          console.log("got token");
          settoken(token);
          
          const response = await fetch(
            process.env.REACT_APP_API_ENDPOINT + "/auth/" + id,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          setrefreshing(false);
          sethouse(data);
        } else {
          console.log("Uauth");
        }
      }
    }
  }, [getAccessTokenSilently, id, isAuthenticated, isLoading]);
  
  //HANDLES INPUT CHANGE
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setstep2Data(values => ({...values, [name]: value}))
  }

  const handleClick = (event) => {
    const name = event.target.name;
    const value = !step3Data[name];
    setstep3Data(values => ({...values, [name]: value}))
  }






   //HOUSE CREATION / EDITING START
  function renderSteps(uppy) {
    switch (currentpage) {
      case 1:
        return Step1();
      case 2:
        return Step2(uppy);
      case 3:
        return Step3()
      case 4:
        return Step4()
      default:
        return "foo";
    }
  }
  function Step1() {
    var activeIndex = accindex;

    return (
      <div>
        <Accordion>
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={() => setaccindex(0)}
          >
            <Icon name="dropdown" />
            What is a dog?
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <p>
              A dog is a type of domesticated animal. Known for its loyalty and
              faithfulness, it ca
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 1}
            index={1}
            onClick={() => setaccindex(1)}
          >
            <Icon name="dropdown" />
            What kinds of dogs are there?
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 1}>
            <p>
              There are many breeds of dogs. Each breed varies in size and
              temper
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 2}
            index={2}
            onClick={() => setaccindex(2)}
          >
            <Icon name="dropdown" />
            How do you acquire a dog?
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 2}>
            <p>
              Three common ways for a prospective owner to acquire a dog is from
              pet shops, private owners, or shelters.
            </p>
            <p>
              A pet shop may be the most convenient way to buy a dog. Buying a
              dog from a
            </p>
          </Accordion.Content>
        </Accordion>
        <div>
          <Button
            primary
            icon
            loading={refreshing}
            labelPosition="right"
            type="submit"
            onClick={() => setcurrentpage(2)}
          >
            Next <Icon name="right arrow" />
          </Button>
        </div>
      </div>
    );
  }
  function Step2(uppy) {
    var basicfeatures = house.basicfeatures
    if (basicfeatures === undefined) {
      basicfeatures['beds'] = 0
      basicfeatures['baths'] = 0
      basicfeatures['msq'] = 0
    }
    return (
      <Form onSubmit={()=> BasicPush()}>
        <Form.Field>
          <Input placeholder="H ouse Name" name="HouseName" value={house.name} onChange={handleChange}/>
        </Form.Field>
        <Form.Field>
          <Input placeholder="Somewhere, VIC" name="Location" value={house.name} onChange={handleChange}/>
        </Form.Field>
        <Form.Field>
          <Input
          name="Beds"
            placeholder="Beds"
            label={{ basic: true, content: "Beds" }}
            labelPosition="right"
            value={basicfeatures.beds}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Input
          name="Baths"
            placeholder="Baths"
            label={{ basic: true, content: "Baths" }}
            labelPosition="right"
            value={basicfeatures.baths}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field >
          <Input
          name="Msq"
          r
            placeholder="msq"
            label={{ basic: true, content: "MSQ" }}
            labelPosition="right"
            value={basicfeatures.msq}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Input
          name="Tags"
            icon="tags"
            iconPosition="left"
            label={{ tag: true, content: "Add Tag" }}
            value={house.tags}
            labelPosition="right"
            placeholder="Enter tags"
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Dashboard
            uppy={uppy}
            proudlyDisplayPoweredByUppy={false}
            metaFields={metaFields}
          />
        </Form.Field>
        <div>
          <Button
            secondary
            icon
            loading={refreshing}
            labelPosition="left"
            onClick={() => setcurrentpage(1)}
          >
            Previes <Icon name="left arrow" />
          </Button>
          <Button
            primary
            icon
            loading={refreshing}
            labelPosition="right"
            type="submit"
          >
            Next <Icon name="right arrow" />
          </Button>
        </div>
      </Form>
    );
  }
  function Step3() {
    var basicfeatures = house.basicfeatures
    if (basicfeatures === undefined) {
      basicfeatures['beds'] = 0
      basicfeatures['baths'] = 0
      basicfeatures['msq'] = 0
    }
    return (
      <Form onSubmit={()=> FeaturesPush()}>
        <Form.Field>
        <Button toggle active={step3Data.b1} name="b1" onClick={handleClick}>
        Toggle
      </Button>
      </Form.Field>
      <Form.Field>
      <Button toggle active={step3Data.b2} name="b2" onClick={handleClick}>
        Toggle
      </Button>
      </Form.Field>
      <Form.Field>
      <Button toggle active={step3Data.b3} name="b3" onClick={handleClick}>
        Toggle
      </Button>
      </Form.Field>
      <Form.Field>
      <Button toggle active={step3Data.b4} name="b4" onClick={handleClick}>
        Toggle
      </Button>
      </Form.Field>
      <Form.Field>
      <div>
          <Button
            secondary
            icon
            loading={refreshing}
            labelPosition="left"
            onClick={() => setcurrentpage(2)}
          >
            Previes <Icon name="left arrow" />
          </Button>
          <Button
            primary
            icon
            loading={refreshing}
            labelPosition="right"
            type="submit"
          >
            Next <Icon name="right arrow" />
          </Button>
        </div>
        </Form.Field>
      </Form>
    );
  }
  function Step4() {
    
    return (
      
      <div>
          <Button
            secondary
            icon
            loading={refreshing}
            labelPosition="left"
            onClick={() => setcurrentpage(2)}
          >
            Previes <Icon name="left arrow" />
          </Button>
          <Button
            primary
            icon
            loading={refreshing}
            labelPosition="right"
            type="submit"
            onClick={() => Submit()}
          >
            Submit For Review <Icon name="right arrow" />
          </Button>
        </div>
    );
  }
  function Steps() {
    return (
      <Step.Group>
        <Step active={currentpage === 1}>
          <Icon name="elegibility" />
          <Step.Content>
            <Step.Title>Elegibility</Step.Title>
            <Step.Description>check your house</Step.Description>
          </Step.Content>
        </Step>

        <Step active={currentpage === 2}>
          <Icon name="basics" />
          <Step.Content>
            <Step.Title>Basics</Step.Title>
            <Step.Description>name, photos, bed, baths</Step.Description>
          </Step.Content>
        </Step>

        <Step active={currentpage === 3}>
          <Icon name="features" />
          <Step.Content>
            <Step.Title>Features</Step.Title>
            <Step.Description>Sustainability Features</Step.Description>
          </Step.Content>
        </Step>
        <Step disabled={currentpage !== 4}>
          <Icon name="submit" />
          <Step.Content>
            <Step.Title>Submit!</Step.Title>
          </Step.Content>
        </Step>
      </Step.Group>
    );
  }

  async function BasicPush(event) {
    console.log(step2Data)
    setrefreshing(true);
    var data = {
      name: step2Data['HouseName'],
      tags: step2Data['Tags'],
      basicfeatures: {
        beds: step2Data['Beds'],
        baths: step2Data['Baths'],
        msq: step2Data['Msq']
      },
      location: step2Data['Location']
    }
    var endpoint = process.env.REACT_APP_API_ENDPOINT + "/house/" + id
    console.log(endpoint)
    console.log(data)
    console.log(`Bearer ${token}`)
    const response = await fetch(
      endpoint,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": 'application/json'
        },
        body: JSON.stringify(data)
      }
    );
      console.log(response)
    setrefreshing(false);
    setcurrentpage(3)
  }
  async function FeaturesPush() {
    setrefreshing(true);
    setcurrentpage(4)
    setrefreshing(false);
  }
  async function Submit() {
    setrefreshing(true);
    
    await fetch(
      process.env.REACT_APP_API_ENDPOINT + "/house/" + id,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": 'application/json'
        },
        body: JSON.stringify({submitted: true})
      }
    );
    setcurrentpage(0)
    window.location.reload(false);
  }
   //HOUSE CREATION / EDITING FINISH


  function EditExisting() {
    var tagg = "Submitted"

    if (house.active === false && house.reviewed === false && house.submitted === false) {
      tagg = "Not Submitted"
    }
    if (house.active === false && house.reviewed === true && house.submitted === true) {
      tagg = "Reviewed, Private"
    }
    if (house.active === true && house.reviewed === true && house.submitted === true) {
      tagg = "Public"
    }
    if (house.active === false && house.reviewed === false && house.submitted === true) {
      tagg = "Waiting for Review"
    }
    return(
      <>
      <PlasmicRootProvider loader={PLASMIC}>
        <PlasmicComponent component="House Card" componentProps={{
          noOwner: true,
          title: house.name,
          location: house.location,
          detail: house.created_at,
          tags: tagg
        }}/>
      </PlasmicRootProvider>
    </>
    )
  }




  return (
    <div
      class=""
      style={{
        backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2) ), url("https://scontent-syd2-1.xx.fbcdn.net/v/t1.6435-9/196315106_2917315145153717_9059184619568423288_n.jpg?_nc_cat=105&ccb=1-5&_nc_sid=a26aad&_nc_ohc=lzlxg4xAdTYAX-8gyrN&_nc_ht=scontent-syd2-1.xx&oh=beebe566d488a5db013b7743fe73a638&oe=61859221")`,
      }}
    > 

      <script
        src="https://upload-widget.cloudinary.com/global/all.js"
        type="text/javascript"
      ></script>
      <PlasmicRootProvider loader={PLASMIC}>
        <PlasmicComponent
          component="Header"
          componentProps={{
            light: true,
          }}
        />
      </PlasmicRootProvider>

      <div class="centrebox">
        <Segment class="centrebox">
        {house ? (
          <>
          {house.submitted ? (
            EditExisting()
          ) : (
            <>
          {Steps()}
          {renderSteps(uppy)}
          </>
          )}
          </>
        ) : (
          <>
        <Dimmer active inverted>
          <Loader inverted content="Loading" />
          
        </Dimmer>
         <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
        </>
        )}
        </Segment>
      </div>
      <PlasmicRootProvider loader={PLASMIC}>
        <PlasmicComponent component="Footer" />
      </PlasmicRootProvider>
    </div>
  );
}
