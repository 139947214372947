import './App.css';
import './global.css';
import React from 'react';
import {Home} from "./pages/home.js"
import { House } from './pages/house';
import { Account } from './pages/account';
import { Discover } from './pages/discover';
import { Builder } from './pages/builder';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import 'semantic-ui-css/semantic.min.css'
import { PlasmicRootProvider, PlasmicCanvasHost } from '@plasmicapp/loader-react';
import { PLASMIC } from './plasmic-init';
import { IKContext } from 'imagekitio-react';
import LogRocket from 'logrocket';
import { QueryClient, QueryClientProvider } from "react-query";
const publicKey = 'public_6dr1ANgoGxpWXbMFqqnJfkxCyJ0='; 
const authenticationEndpoint = process.env.REACT_APP_API_ENDPOINT + "/Builder/Auth";
const urlEndpoint = 'https://ik.imagekit.io/twigged';

  const queryClient = new QueryClient();
function App() {

  if (process.env.REACT_APP_TOOLS) {
  LogRocket.init('fvnlxu/twigged');
  }
  

  return (
    <PlasmicRootProvider loader={PLASMIC} >
    <QueryClientProvider client={queryClient}>
    <Router>
      <div>
        <IKContext publicKey={publicKey} urlEndpoint={urlEndpoint} authenticationEndpoint={authenticationEndpoint}>
        

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
        <Route path="/plasmic-host" render={() => <PlasmicCanvasHost />} />
          <Route path="/account">
            <Account />
          </Route>
          <Route path="/discover">
            <Discover />
          </Route>
          <Route path="/builder/:id">
            <Builder />
            </Route>
          <Route path="/houses/:house">
          <House />
        </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        </IKContext>
      </div>
    </Router>
    </QueryClientProvider>
    </PlasmicRootProvider>
  );
}






export default App;
