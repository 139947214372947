import React from "react";
//import {OnLogin, OnLogout,OnCreateAccount} from '../shared/global.js'
import "../App.css";
import { useParams } from "react-router-dom";

import { Comment, Button, Form, Placeholder } from "semantic-ui-react";
import { useState, useEffect } from "react";
import { OwnerTag } from "../stories/OwnerTag";
import { useHistory } from "react-router-dom";
import {
  PlasmicRootProvider,
  PlasmicComponent,
} from "@plasmicapp/loader-react";
import { PLASMIC } from "../plasmic-init";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";

export function House() {
  const { house } = useParams();

  async function FetchHouse() {
    var ldata = await (
      await fetch(process.env.REACT_APP_API_ENDPOINT + "/house/" + house, {})
    ).json();
    console.log(ldata);
    return ldata;
  }

  const { data, error, status } = useQuery("AccData", FetchHouse);

  function GenerateQuestions() {
    const questions = data.question.map((question) => (
      <Comment>
        <Comment.Avatar src={question.profile} />
        <Comment.Content>
          <Comment.Author as="a">{question.user}</Comment.Author>
          <Comment.Metadata>
            <div>{question.created_at}</div>
          </Comment.Metadata>
          <Comment.Text>{question.text}</Comment.Text>
          <Comment.Actions>
            <Comment.Action>Reply</Comment.Action>
          </Comment.Actions>
        </Comment.Content>
      </Comment>
    ));
    return questions;
  }
  /*{houseData && (
      GenerateQuestions())} 
      {houseData ? (
      ) : {}}
      
      */

  return (
    <div>
      {data ? (
        <>
      <PlasmicRootProvider loader={PLASMIC}>
        <PlasmicComponent
          component="House"
          componentProps={{
            houseName: data.name,
            basicfeatures: `${data.basicfeatures.msq} msq | ${data.basicfeatures.beds} Beds | ${data.basicfeatures.baths} Baths`,
            owner: {
              ownerName:`By ${data.ownerObject.name}`,
            },
            designer: {
              ownerName:`By Not Implemented`,
            },
            mainphoto: data.mainphoto,
            planphoto: data.planphoto,
          }}
        />
      </PlasmicRootProvider>

        
          <div
            class="heroimage"
            style={{
              height: "545px",
              backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2) ), url("${data.mainphoto}")`,
            }}
          />
        </>
      ) : (
        <Placeholder>
          <Placeholder.Header image />
        </Placeholder>
      )}
      <body class="thinsection">
        <div>
          {data ? (
            <div class="HouseContainer">
              <OwnerTag
                name={data.ownerObject.name}
                id={data.ownerObject.id}
                image={data.ownerObject.picture}
              />

              <div class="CardLine">
                <div class="houseName">{data.name}</div>
                <div class="placeName">{data.location}</div>
              </div>

              <div class="bAB">
                {data.basicfeatures.msq}msq | {data.basicfeatures.beds} Beds |{" "}
                {data.basicfeatures.bath} Bath
              </div>
              <div class="ChipBag">{data.tags}</div>
            </div>
          ) : (
            <Placeholder>
              <Placeholder.Header image />
            </Placeholder>
          )}
          <div>
            <Comment.Group>
              {data ? (
                GenerateQuestions()
              ) : (
                <Placeholder>
                  <Placeholder.Header image />
                </Placeholder>
              )}

              <Form reply>
                <Form.TextArea />
                <Button
                  content="Add Reply"
                  labelPosition="left"
                  icon="edit"
                  primary
                />
              </Form>
            </Comment.Group>
          </div>
        </div>
      </body>
      <PlasmicComponent component="Footer" />
    </div>
  );
}
