//import {OnLogin, OnLogout,OnCreateAccount} from '../shared/global.js'
import { useAuth0 } from "@auth0/auth0-react";
import { HouseList } from "../stories/HouseList";
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import {
  Placeholder,
  Icon,
  Segment,
  Dimmer,
  Button,
  Loader,
  Image,
} from "semantic-ui-react";
import {
  PlasmicRootProvider,
  PlasmicComponent,
} from "@plasmicapp/loader-react";
import { PLASMIC } from "../plasmic-init";
import { useHistory } from "react-router";
var housedata = [
  {
    owner: {},
    loading: true,
  },
  {
    owner: {},
    loading: true,
  },
  {
    owner: {},
    loading: true,
  },
  {
    owner: {},
    loading: true,
  },
];
export function Account() {
  const {
    loginWithRedirect,
    user,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
  } = useAuth0();
  const history = useHistory();
  if (!isAuthenticated && !isLoading) {
    loginWithRedirect({ returnTo: window.location.origin });
  }

  
  //const [accountData, setaccountData] = useState(undefined);
  const { logout, onLogin, onCreateAccount } = useAuth0();
  async function fetchAllUsers () {

    const token = await getAccessTokenSilently({
      audience: "Hosted on AWS",
    });

    var ldata = await (await fetch(process.env.REACT_APP_API_ENDPOINT + "/user", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })).json();
    console.log(ldata);
    return ldata;
  }

  const { data, error, status } = useQuery("AccData", fetchAllUsers);



  async function createhouse() {
    const token = await getAccessTokenSilently({
      audience: "Hosted on AWS",
    });
    console.log("got token");

    const response = await fetch(
      process.env.REACT_APP_API_ENDPOINT + "/house",
      {
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await response.json();
    history.push("/builder/" + data["pk"]);
  }
  async function createStripeAccount() {
    const token = await getAccessTokenSilently({
      audience: "Hosted on AWS",
    });
    console.log("got token");

    const response = await fetch(
      process.env.REACT_APP_API_ENDPOINT + "/user/stripe",
      {
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await response.json();
    console.log("Redirecting to Stripe: " + data.url)
    if (data.url !== undefined) {
    window.location.href = data.url;
    }

  }




  return (
    <div>
      
      <PlasmicRootProvider loader={PLASMIC}>
        <PlasmicComponent
          component="Header"
          componentProps={{ variants: { light: true } }}
        />
      </PlasmicRootProvider>

      <div
        class="heroimage"
        style={{
          height: "500px",
          backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2) ), url("https://scontent-syd2-1.xx.fbcdn.net/v/t1.6435-9/196315106_2917315145153717_9059184619568423288_n.jpg?_nc_cat=105&ccb=1-5&_nc_sid=a26aad&_nc_ohc=lzlxg4xAdTYAX-8gyrN&_nc_ht=scontent-syd2-1.xx&oh=beebe566d488a5db013b7743fe73a638&oe=61859221")`,
        }}
      >
        <div class="herobox">
          {isLoading ? (
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line length="long" />
                <Placeholder.Line length="short" />
              </Placeholder.Header>
            </Placeholder>
          ) : (
            <>
              <text class="herotext">Welcome</text>
              <text class="tagline">Hi {user.nickname}</text>
            </>
          )}
        </div>
      </div>

      <body class="houseSection">
        {data !== undefined && isAuthenticated ===true ? (
          <>
            {user ? (
              <>
              
                <Button
                  size="small"
                  onClick={() => logout({ returnTo: window.location.origin })}
                  label="Log out"
                />
              </>
            ) : (
              <>
                <Button size="small" onClick={onLogin} label="Log in" />
                <Button
                  primary
                  size="small"
                  onClick={onCreateAccount}
                  label="Sign up"
                />
              </>
            )}
            <Segment onClick={() => createhouse()}>
              <text>Create a new house   </text>
              <Icon name="arrow circle right"></Icon>
            </Segment>
            {data.stripeid ? (
            <Segment onClick={() => createStripeAccount()}>
            <text>Stripe Connected!   </text>
              <Icon name="arrow circle right"></Icon>
            </Segment>
            ) : (
            <Segment onClick={() => createStripeAccount()}>
            <text>Connect Stripe to take payments for your house plans   </text>
              <Icon name="arrow circle right"></Icon>
            </Segment>)}
          </>
        ) : (
          <>
            <Segment>
              <Dimmer active inverted>
                <Loader inverted content="Loading" />
              </Dimmer>

              <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
            </Segment>
            <Segment>
              <Dimmer active inverted>
                <Loader inverted content="Loading" />
              </Dimmer>

              <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
            </Segment>
          </>
        )}
        {data ? (
          <>
            <text class="listheader">
              Your <text style={{ color: "B6A99A" }}>Houses</text>
            </text>
            {data.house.length >0 ? (
              <HouseList edit={true} housejson={data.house}></HouseList>
            ) : (
              <div>No Houses Here? you should create one!</div>
            )}
            <text class="listheader">
              Your <text style={{ color: "B6A99A" }}>Favs</text>
            </text>
            {data.favs.length >0  ? (
              <HouseList housejson={data.favs}></HouseList>
            ) : (
              <div>No Favs go favorite some!</div>
            )}
          </>
        ) : (
          <>
            <text class="listheader">
              Your <text style={{ color: "B6A99A" }}>Houses</text>
            </text>
            <HouseList housejson={housedata}></HouseList>
            <text class="listheader">
              Your <text style={{ color: "B6A99A" }}>Favs</text>
            </text>
            <HouseList housejson={housedata}></HouseList>
          </>
        )}
      </body>
      <PlasmicRootProvider loader={PLASMIC}>
        <PlasmicComponent component="Footer" />
      </PlasmicRootProvider>
    </div>
  );
}
