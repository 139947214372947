import React from "react";
import PropTypes from "prop-types";
import "./HouseCard.css";
import { Chip } from "./chip";
import { useHistory } from "react-router-dom";
import { Placeholder } from "semantic-ui-react";
import { PlasmicComponent } from "@plasmicapp/loader-react";



/**
 * Primary UI component for viewing houses
 */
export const HouseCard = ({
  house,
  loading,
  editcard
}) => {

  if (house.mainphoto == null) {
    house.mainphoto = "https://worldwellnessgroup.org.au/wp-content/uploads/2020/07/placeholder.png"
  }

console.log(house.name +" " + JSON.stringify(house.basicfeatures))
const history = useHistory();
    if (loading!=true) {
    var chips = ""
    if (house.tags != undefined && house.tags.length > 0) {
      chips = house.tags.map((d) => <Chip label={d} chipColor="#b6a99a"></Chip>);
    }    
  

  var historyloc = "/houses/"+house.id
  if (editcard === true) {
    historyloc = "/builder/"+house.id
  }

  if (house.basicfeatures === undefined ||house.basicfeatures === null || house.basicfeatures.beds === null) {
    console.log("BasicFeautures not present")
    house.basicfeatures = {
  beds: 0,
  baths: 0,
  msq: 0,
  }
  
  }
  }
  return (
    <div>
      {loading ? (
        <div class="HouseContainer">
          <Placeholder class="HouseContainer">
            <Placeholder.Image square />

            <Placeholder.Header>
              <Placeholder.Line />

              <Placeholder.Line length="short" />
              <div style={{ float: "left" }}>
                <Placeholder.Line length="very short" />
                <Placeholder.Line length="very short" />
              </div>
            </Placeholder.Header>
          </Placeholder>
        </div>
      ) : (
        <div class="HouseContainer">

        
        <PlasmicComponent 
  component="House Card"
  componentProps={{
        title: house.name,
        edit:editcard,
        picture: house.mainphoto,
        location: house.location,
        ownerPicture: house.ownerObject.picture,
        detail: `${house.basicfeatures.msq} msq | ${house.basicfeatures.beds} Beds | ${house.basicfeatures.baths} Baths`,
        houseOwner: {
          OwnerName:`By ${house.ownerObject.name}`,
        },
        onClick: () => {history.push(historyloc)},
        onFavorite: () => {console.log("Button was favorited");},
        onOwner: () => {history.push("/people/"+house.ownerObject.id);}
      }
  }
  
      />
      </div>
      )}
    </div>
  );
};

HouseCard.propTypes = {
  /**
   * name of the house
   */
  houseName: PropTypes.string,
  /**
   * place name text
   */
  placeName: PropTypes.string,
  /**
   * metres sqaured value
   */
  msq: PropTypes.number,
  /**
   * house beds
   */
  beds: PropTypes.number,
  /**
   * house baths
   */
  baths: PropTypes.number,
  /**
   * tags, comma seperated
   */
  tags: PropTypes.string,

  loading: PropTypes.bool,
};

HouseCard.defaultProps = {
  houseName: null,
  msq: 0,
  beds: 0,
  baths: 0,
  loading: false,
  tags: ""
};
